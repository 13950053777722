.orderDetails {
    display: flex;
    flex-direction: column;
    width: 44%;
    height: 252px;
    flex-shrink: 0;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);

    padding-inline-start: 41px;
    padding-top: 41px;
    margin-bottom: 32px;
    box-sizing: border-box;
}

.title {
    color: #000;
    font-size: 24px;
    /* font-family: Normalidad Medium Var; */
    font-style: normal;
    font-weight: 500;
    /* line-height: 16px; */
    margin-bottom: 36px;
}

.detailsDiv {
    display: flex;
    flex-direction: row;
    gap: 21px;
}

.img {
    display: flex;
    width: 124px;
    height: 124px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    box-sizing: border-box;
}

.details {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.orderTitle {
    color: #000;
    font-size: 20px;
    /* font-family: Poppins; */
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

.orderDetail{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    
}

.orderDetailText {
    color: #6B7280;
    font-size: 14px;
    /* font-family: Poppins; */
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}


@media (max-width: 992px) {
    .orderDetails {
        
        width: 100%;
        height: 252px;
        flex-shrink: 0;
    
    
        padding-inline-start: 16px;
        box-sizing: border-box;
        padding-top: 41px;
    }


}