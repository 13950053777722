.error{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;


    color: #6B7280;
    font-size: 20px;
    /* font-family: Normalidad Medium Var; */
    font-style: normal;
    font-weight: 500;
    /* line-height: 16px; */
    margin-inline-end: 28px;
}