.searchbar {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 48px; */
    margin-bottom: 32px;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    /* padding-inline-start: 41px; */
    flex-wrap: wrap;
    box-sizing: border-box;
    gap: 32px;
}

.title {
    color: #1D1D1D;
    font-size: 20px;
    /* font-family: Normalidad Medium Var; */
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-inline-end: 28px;
}


@media (max-width: 600px) {

    .searchbar {
        height: 180px;
        padding-inline: 16px;

    }

    .title {
    
        /* margin-inline-end: 0px; */
    }
   
  }