.searchinput {
    display: flex;
    width: 44%;
    height: 50px;
    /* padding: 16px; */
    padding-inline-start: 16px;
    padding-inline-end: 5px;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;

    border-radius: 50px 50px 50px 60px;
    background: #F3F9FF;
    box-sizing: border-box;

}

.icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.input {
    display: flex;
    height: 20px;
    width: 100%;
    flex-direction: column;
    /* flex: 1 0 0; */
    border-color: transparent;
    background-color: transparent;

    color: #6B7280;
    font-size: 14px;
    /* font-family: Normalidad Light Var; */
    font-style: normal;
    font-weight: 403;
    line-height: 20px;
    overflow: hidden;
}

input:focus {
    outline:none;
}

@media (max-width: 600px) {

    .searchinput {
        width: 100%;
    
    }


}