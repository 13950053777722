.home {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    /* background-color: blue; */
}

.loader{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}


.row{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}