.orderTimelineStatus {

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

}


.status {
    display: flex;
    align-items: center;
    gap: 16px;

}



.timestamp {
    display: flex;
    /* gap: 23px; */
    align-items: center;

    color: #6B7280;
font-size: 12px;
/* font-family: Normalidad Regular Var; */
font-style: normal;
font-weight: 507;
line-height: 16px;
width: 40%;
justify-content: space-between;

}

.calendar {
    margin-inline-end: 6px;
}

.date {
    display: flex;
    gap: 8px;
    align-items: center;
}

.time {
    display: flex;
    gap: 8px;
    align-items: center;
}


@media (max-width: 600px) {
    .timestamp {
        gap: 8px;
        flex-direction: column;
        align-items: flex-start;
    }
}