.App {
  display: flex;
  max-width: 1440px;
  /* background-color: red; */
  padding-right: 64px;
  padding-left: 64px;
  margin: auto;
}

@media (max-width: 600px) {
  .App {
    display: flex;
    max-width: 600px;
    /* background-color: red; */
    padding-right: 16px;
    padding-left: 16px;
    margin: auto;
  }
}

